import { serviceCardData } from "../../utils/types";
import { BsChevronCompactDown } from "react-icons/bs";

const ServiceCard = (props: serviceCardData) => {
  // console.log(window.innerWidth);

  const { openIndex, currentIndex } = props;

  const handleClick = (index: number) => {
    index === openIndex
      ? props.setCurrentIndex(null)
      : props.setCurrentIndex(index);
  };

  return (
    <div
      onClick={() => {
        if (window.innerWidth < 1024) {
          handleClick(currentIndex);
        }
      }}
      onMouseEnter={() => {
        if (window.innerWidth >= 1024) {
          handleClick(currentIndex);
        }
      }}
      onMouseLeave={() => {
        if (window.innerWidth >= 1024) {
          props.setCurrentIndex(null);
        }
      }}
      id={`serviceCard-${props.id}`}
      className={`p-5 rounded-lg border-gray-200 max-h-[900px] group w-full shadow-md sm:absolute text-gray-900 cursor-pointer transition-colors duration-300 ${
        openIndex === currentIndex
          ? " bg-gradient-to-r from-[#21bed5] via-[#4c4b91] to-[#59297d] text-white z-40"
          : "h-72"
      }`}
    >
      <div className="flex justify-center items-center h-[120px]">
        <div className="w-16 h-16 overflow-hidden">
          <img
            className="w-full object-cover"
            src={props.icon ?? ""}
            alt={props.title}
          />
        </div>
      </div>
      <div className="text-2xl font-thin tracking-tight h-20 flex items-start justify-center text-center">
        <p>{props.title ?? "Title"}</p>
      </div>
      <div
        id={`desc-text-${props.id}`}
        className={`"font-normal text-justify" ${
          openIndex !== currentIndex ? " hidden" : ""
        }`}
      >
        <p>{props.description}</p>
      </div>
      <button
        id={`caret-${props.id}`}
        className={`flex justify-center bottom-2 sm:absolute left-0 w-full transform transition-transform duration-500 ${
          openIndex === currentIndex ? " rotate-180" : ""
        }`}
      >
        <BsChevronCompactDown size={24} />
      </button>
    </div>
  );
};

export default ServiceCard;
