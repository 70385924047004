import AboutSection from "../components/aboutSection";
import ContactSection from "../components/contactUs";
import FooterSection from "../components/footer";
import HeaderSection from "../components/header";
import ServiceSection from "../components/serviceSection";
import SideBar from "../components/sideBar";
import TeamSection from "../components/teamSection";

const IndexPage = () => {
  return (
    <div className="">
      <HeaderSection />
      <div className="pt-[55dvh] sm:pt-[650px] z-40 absolute h-screen">
        <div className="flex flex-col gap-20 pb-10 px-2 sm:px-0 bg-white">
          <div className="w-full -mt-[20%]">
            <img src="/images/wave.svg" className="object-cover" alt="wave" />
          </div>
          <AboutSection />
          <ServiceSection />
          {/* <CounterSection /> */}
          <TeamSection />
        </div>
        <ContactSection />
        <FooterSection />
      </div>
      <SideBar />
    </div>
  );
};

export default IndexPage;
