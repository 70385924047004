import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation();
  return (
    <div
      id="about"
      className="h-auto px-2"
      data-aos="zoom-out"
      data-aos-duration="500"
      data-aos-delay="30"
      data-aos-once="true"
    >
      <div>
        <h1 className="text-2xl my-2 font-bold text-center">
          {t("WELCOME_TITLE")}
        </h1>
        <p className="my-2 text-justify">
          {t("WELCOME_FIRST")} <br /> {t("WELCOME_SECOND")}
        </p>
      </div>
    </div>
  );
};

export default AboutSection;
