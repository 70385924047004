// import * as React from 'react';
import { Fragment } from "react";
import { languageIcons, sectionList } from "../utils/constants";
import { GrClose } from "react-icons/gr";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../context/globalContext";

const SideBar = () => {
  const { i18n } = useTranslation();
  const { showingSideBar, setshowingSideBar } = useContext(GlobalContext);

  const scrollToSection = (id: string) => {
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setshowingSideBar(false);
    }
  };

  return (
    <Fragment>
      <div
        className={`fixed w-screen h-screen bg-black/10 top-0 left-0 transition z-50 sm:hidden ${
          !showingSideBar && " opacity-0 pointer-events-none"
        } `}
        onClick={() => setshowingSideBar(false)}
      ></div>
      <div
        className={`fixed h-[100dvh] backdrop-blur-lg w-[70vw] z-50 top-0 left-0 bg-white/80 shadow-lg sm:hidden transform transition ${
          !showingSideBar && " -translate-x-full"
        }`}
      >
        <div className="relative w-full h-full flex items-center">
          <div className="absolute top-2 pl-10 left-0 right-0 w-full flex gap-2 items-center justify-end">
            <button
              onClick={() => setshowingSideBar(false)}
              className="p-4 active:scale-75"
            >
              <GrClose />
            </button>
          </div>
          <ul className="animClass w-full">
            {sectionList.map((item) => (
              <li
                className={`text-lg py-3 font-sans font-semibold hover:font-bold w-full flex justify-center ${
                  showingSideBar ? " fadeInLeft" : ""
                }`}
                key={`side-${item.title}`}
              >
                <button
                  className="hover:scale-110 text-left w-24 text-gray-600"
                  onClick={() => {
                    scrollToSection(item.link);
                  }}
                >
                  {item.title}
                </button>
              </li>
            ))}
          </ul>

          <div className="absolute bottom-5 w-full px-4">
            <div className="flex items-center gap-2 border-b py-3 border-black/10 justify-end w-full">
              {Object.keys(languageIcons).map((key) => (
                <button
                  key={key}
                  className={`p-1 rounded border-2 active:scale-75 ${
                    i18n.language === key
                      ? " border-teal-400"
                      : " border-transparent "
                  }`}
                  onClick={() => {
                    i18n.changeLanguage(key);
                    setshowingSideBar(false);
                  }}
                >
                  <img src={languageIcons[key]} alt={key} />
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SideBar;
