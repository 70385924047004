import {
  FaLinkedin,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagramSquare,
} from 'react-icons/fa';
// import { ReactNode } from "react";
export const linkTypes = {
  LINKEDIN: 'Linkedin',
  FACEBOOK: 'Facebook',
  GITHUB: 'Github',
  INSTAGRAM: 'Instagram',
};

export const teamData = [
  {
    id: 1,
    avatar: '/images/team/tahezeeb.jpeg',
    name: 'Tahezeeb Jayla',
    role: 'Founder',
    desc: '',
    active: true,
    links: [
      {
        show: true,
        type: 'LINKEDIN',
        link: 'https://www.linkedin.com/in/tanin-jayla-6924391a1/',
        element: FaLinkedin,
      },
    ],
  },
  {
    id: 2,
    avatar: '/images/team/maidul.jpeg',
    name: 'Maidul Islam',
    role: 'Co-Founder',
    desc: '',
    active: true,
    links: [
      {
        show: true,
        type: 'LINKEDIN',
        link: 'https://www.linkedin.com/in/rashiq-rahman/',
        element: FaLinkedin,
      },
    ],
  },
  {
    id: 3,
    avatar: '/images/team/meraj.jpg',
    name: 'Merajul Islam',
    role: 'Software Developer',
    desc: '',
    active: true,
    links: [
      {
        show: true,
        type: 'LINKEDIN',
        link: 'https://www.linkedin.com/in/merajul-islam1/',
        element: FaLinkedin,
      },
    ],
  },
  {
    id: 4,
    avatar: '/images/team/imran.jpg',
    name: 'Minhajul Islam',
    role: 'Data Engineer',
    desc: '',
    active: true,
    links: [
      {
        show: true,
        type: 'LINKEDIN',
        link: 'https://www.linkedin.com/in/md-minhajul-islam-21702115b/',
        element: FaLinkedin,
      },
    ],
  },
  {
    id: 5,
    avatar: '/images/team/rashiq.jpg',
    name: 'Rashiq Rahman',
    role: 'Software Developer',
    desc: '',
    active: true,
    links: [
      {
        show: true,
        type: 'LINKEDIN',
        link: 'https://www.linkedin.com/in/rashiq-rahman/',
        element: FaLinkedin,
      },
    ],
  },
];

export const serviceData = [
  {
    id: Math.random().toString(16),
    title: 'SERVICE_ONE_TITLE',
    icon: '/images/service/software.png',
    description: 'SERVICE_ONE_DESC',
  },
  {
    id: Math.random().toString(16),
    title: 'SERVICE_TWO_TITLE',
    icon: '/images/service/mobile.png',
    description: 'SERVICE_TWO_DESC',
  },
  {
    id: Math.random().toString(16),
    title: 'SERVICE_THREE_TITLE',
    icon: '/images/service/data_solution.png',
    description: 'SERVICE_THREE_DESC',
  },
  {
    id: Math.random().toString(16),
    title: 'SERVICE_FOUR_TITLE',
    icon: '/images/service/data_analysis.png',
    description: 'SERVICE_FOUR_DESC',
  },
  {
    id: Math.random().toString(16),
    title: 'SERVICE_FIVE_TITLE',
    icon: '/images/service/data_mig.png',
    description: 'SERVICE_FIVE_DESC',
  },
  {
    id: Math.random().toString(16),
    title: 'SERVICE_SIX_TITLE',
    icon: '/images/service/web_scrap.png',
    description: 'SERVICE_SIX_DESC',
  },
  // {
  //   id: Math.random().toString(16),
  //   title: "SERVICE_SEV_TITLE",
  //   icon: "/images/service/data_vis.png",
  //   description: "SERVICE_SEV_DESC",
  // },
  // {
  //   id: Math.random().toString(16),
  //   title: "SERVICE_EIGHT_TITLE",
  //   icon: "/images/service/data_ware.png",
  //   description: "SERVICE_EIGHT_DESC",
  // },
];

export const sectionList = [
  {
    title: 'About',
    link: '#about',
  },
  {
    title: 'Services',
    link: '#service',
  },
  {
    title: 'Team',
    link: '#team',
  },
  {
    title: 'Contact',
    link: '#contact',
  },
];

export const languageTitles: any = {
  en: 'English',
  fr: 'Français',
};
export const languageIcons: any = {
  en: '/images/english.png',
  fr: '/images/france.png',
};
