import * as React from "react";
import { teamData } from "../utils/constants";

const TeamSection = () => {
  return (
    <div id="team">
      <h1 className="font-bold text-2xl mb-10 uppercase text-center">
        Meet The Team
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-x-6 gap-y-10">
        {teamData.map((item) => (
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="50"
            data-aos-once="true"
            key={item.id}
            className="text-center"
          >
            <div className="cursor-pointer overflow-hidden relative rounded-[50%] w-52 mx-auto">
              <img
                className="object-cover w-full h-full hover:scale-105 transition-[500ms]"
                src={item.avatar}
                alt={item.name}
              />
            </div>
            <div className="">
              <div className="text-xl font-bold mt-4"> {item.name} </div>
              <div> {item.role} </div>
              <div className="flex justify-center items-center py-2 gap-2">
                {item.links
                  .filter((el) => el.show)
                  .map((linkItem) => (
                    <a key={linkItem.link} href={linkItem.link} target="_">
                      <linkItem.element size={20} />
                    </a>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
