import './header.css';
const HeaderSection = () => {
  return (
    <div className="h-[55dvh] sm:h-[650px] z-10 fixed w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl">
      <div
        className="h-full overflow-hidden"
        // style={{
        //   backgroundImage: `url("/images/bg.jpg")`,
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   backgroundAttachment: "fixed",
        // }}
      >
        <img
          src="/images/bg1.jpg"
          className="object-cover w-full h-full ken"
          alt="banner"
          style={{ backdropFilter: 'blur(6px)' }}
        />
      </div>
      <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center">
        <div className="text-center ">
          <h1
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="300"
            data-aos-once="true"
            className="text-6xl sm:text-8xl text-white font-bold"
          >
            <img
              src="/images/logo.png"
              alt=""
              className=" w-[150px] md:w-[250px] mx-auto"
            />
          </h1>
          <h5
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-delay="400"
            data-aos-once="true"
            className="uppercase font-thin text-[#513e89] text-xl sm:text-2xl my-2"
          >
            {' '}
            Redefine Technology{' '}
          </h5>
        </div>
      </div>
      {/* <div className="custom-shape-divider-bottom-1691176800">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div> */}
    </div>
  );
};

export default HeaderSection;
