import { useState } from "react";
import { useTranslation } from "react-i18next";

const ContactSection = () => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [name, setName] = useState("");

  return (
    <div id="contact" className="relative overflow-hidden h-[700px]">
      <img
        src="/images/bgc.jpg"
        alt=""
        className="w-full h-full object-cover ken"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center backdrop-blur-sm">
        <div className="w-full">
          <div
            data-aos="fade-down"
            data-aos-duration="700"
            data-aos-delay="50"
            data-aos-once="true"
            className="font-bold text-3xl sm:text-6xl mb-8 text-center text-gray-200"
          >
            {t("CONTACT_TAGLINE_ONE")}, <br /> {t("CONTACT_TAGLINE_TWO")}
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              window.open(
                `mailto:info@mavtek.fr?subject=Contact-${name}&body=${message}`
              );
            }}
            className="sm:mx-20 bg-white/50 backdrop-blur-2xl py-10 px-4 sm:px-14 rounded-lg"
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <div className="flex flex-col sm:flex-row gap-3">
              <input
                required
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="px-4 py-2 rounded-lg bg-white/80 w-full border-0 outline-0 my-4"
                placeholder={t("CONTACT_NAME_TITLE")}
              />
            </div>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full p-4 m-0 rounded-lg bg-white/80 border-0 outline-0 mb-4"
              rows={4}
              placeholder={t("YOUR_MESSAGE")}
            />
            <button className="rounded-full w-full bg-gradient-to-r from-[#21bed5] via-[#4c4b91] to-[#59297d] text-white px-6 py-2 hover:scale-[98%] hover:bg-gradient-to-l transform duration-100 active:scale-90 uppercase">
              {t("SUBMIT")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
