import { useEffect, useState } from "react";
import "./App.css";
import IndexPage from "./pages";
import NavbarWithCTAButton from "./components/navBar";
import AOS from "aos";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "./context/globalContext";

function App() {
  const [showingSideBar, setshowingSideBar] = useState<any>(false);

  useEffect(() => {
    AOS.init();
  }, []);
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("fr");
  }, []);

  // const checkIfSideBar = useCallback(() => {
  //   const overlay = document.getElementById("overlay");
  //   return overlay && !overlay.classList.contains("pointer-events-none");
  // }, []);

  return (
    <div className="container mx-auto h-screen relative">
      <GlobalContext.Provider value={{ showingSideBar, setshowingSideBar }}>
        <NavbarWithCTAButton />
        <IndexPage />
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
