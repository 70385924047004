import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import Loader from "./components/Loader";
// const App = lazy(() => {
//   return Promise.all([
//     import("./App"),
//     new Promise((resolve) => setTimeout(resolve, 2000)),
//   ]).then(([moduleExports]) => moduleExports);
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>
);
