import { useContext } from "react";
import { Navbar } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { BsCheckLg } from "react-icons/bs";
import { languageIcons, languageTitles, sectionList } from "../utils/constants";
import { GlobalContext } from "../context/globalContext";
// import { hideSideBar, showSideBar } from "../pages";
import { CiMenuBurger } from "react-icons/ci";
import { RiMenuFoldLine } from "react-icons/ri";

export default function NavbarWithCTAButton() {
  const { showingSideBar, setshowingSideBar } = useContext(GlobalContext);
  const { i18n } = useTranslation();
  const handleLangChange = (val: string) => {
    i18n.changeLanguage(val);
    closeMenu("dropdown");
  };
  const toggleMenu = (id: string) => {
    const menu = document.getElementById(id);
    menu && menu.classList.toggle("hidden");
  };

  const closeMenu = (id: string) => {
    const menu = document.getElementById(id);
    menu && menu.classList.add("hidden");
  };

  const handleClick = () => {
    showingSideBar ? setshowingSideBar(false) : setshowingSideBar(true);
  };

  const scrollToSection = (id: string) => {
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Navbar
      fluid
      rounded
      className=" fixed top-0 z-50 w-full right-0 rounded-none backdrop-blur-2xl bg-white/50  shadow-sm"
    >
      <div className="flex items-center md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl sm:px-4 justify-between w-full mx-auto h-14">
        <Navbar.Brand href="/">
          <span className="self-center whitespace-nowrap text-2xl sm:text-4xl">
            {/* <span className="">⌬</span> */}
            {/* <span className="text-teal-500">⠷</span>
            <span className="font-normal text-teal-500">M</span>
            <span className="font-semibold">᭶</span>
            <span className="font-normal text-teal-500">v</span> */}
            {/* <span className="text-teal-500">⍌</span> */}
            {/* <span className="text-teal-500">▼</span> */}
            {/* <span className="rotate-180">᭶</span> */}
            {/* <span className="font-normal text-teal-500">t</span>
            <span className="font-normal text-teal-500">e</span>
            <span className="font-normal text-teal-500">k</span> */}
            <img
              src="/images/mavtek.png"
              alt="logo"
              className="h-full w-[200px] object-cover"
            />
          </span>
        </Navbar.Brand>
        <div className="flex-1"></div>
        <Navbar.Collapse>
          {sectionList.map((item, index) => (
            <Navbar.Link
              key={`${item.title}-${index}`}
              className="cursor-pointer"
              onClick={() => {
                scrollToSection(item.link);
              }}
            >
              {item.title}
            </Navbar.Link>
          ))}
          <div className="relative hidden sm:block">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleMenu("dropdown");
              }}
              className="flex gap-2 justify-center items-center"
            >
              <img src={languageIcons[i18n.language]} alt={i18n.language} />
              {languageTitles[i18n.language]}
            </button>

            <div
              id="dropdown"
              className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute top-5 right-0"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                {Object.keys(languageTitles).map((key) => (
                  <li
                    key={`lang-${key}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleLangChange(key);
                    }}
                  >
                    <div
                      className={`flex gap-2 justify-start items-center cursor-pointer px-4 py-2 hover:bg-gray-100`}
                    >
                      <img src={languageIcons[key]} alt={key} />
                      {languageTitles[key]}
                      <div className="flex-1"></div>
                      {i18n.language === key ? (
                        <span className="text-green-500">
                          <BsCheckLg size={24} />
                        </span>
                      ) : null}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Navbar.Collapse>
        <button
          onClick={() => {
            handleClick();
          }}
          className={`relative sm:hidden shadow active:scale-75 active:bg-black/10  rounded-md py-1 px-2 flex gap-3 items-center transition
          }`}
        >
          <div className="flex gap-1 justify-center items-center">
            <img src={languageIcons[i18n.language]} alt="" />
          </div>
          {showingSideBar ? <div>{languageTitles[i18n.language]}</div> : null}
          <div>{showingSideBar ? <RiMenuFoldLine /> : <CiMenuBurger />}</div>
        </button>
      </div>
    </Navbar>
  );
}
