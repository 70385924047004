import { useTranslation } from 'react-i18next';
import { serviceData } from '../utils/constants';
import ServiceCard from './common/serviceCard';
import { useState } from 'react';

export default function ServiceSection() {
  const [currentlyOpenIndex, setCurrentlyOpenIndex] = useState(null);

  const { t } = useTranslation();
  return (
    <div id="service">
      <h1 className="text-2xl mb-6 font-bold text-center">
        {t('SERVICE_TITLE')}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-4 justify-center sm:justify-start">
        {serviceData.map((item, index: number) => (
          <div key={item.id} className=" relative sm:h-72">
            <ServiceCard
              openIndex={currentlyOpenIndex}
              currentIndex={index}
              setCurrentIndex={(index: any) => setCurrentlyOpenIndex(index)}
              id={item.id}
              title={t(item.title)}
              description={t(item.description)}
              icon={item.icon}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
