const Loader = () => {
  return (
    <div className="fixed left-0 top-0 w-screen h-screen bg-white flex justify-center items-center">
      <div>
        {" "}
        <img
          src="/images/logo.png"
          alt=""
          className=" w-[150px] md:w-[250px] mx-auto breathing"
        />
      </div>
    </div>
  );
};

export default Loader;
